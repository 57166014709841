.socialLinks {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .link {        
        font-size: 1.2rem;
        padding: 15px 25px; 
        margin: 10px;   
        color: map-get($colors, dark);
        font-family: map-get($fonts, primary);
        text-decoration: none;
        background-color: transparent;
        transition: all 0.3s ease;

        i {
            padding-left: 10px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            border: 2px solid map-get($colors, tropicalice); //livingcoral
            color: map-get($colors, tropicalice);
            cursor: pointer;
        }

        &.-neg {
            border: 2px solid map-get($colors, white);
            color: map-get($colors, white);

            &:hover {
                border: 2px solid map-get($colors, livingcoral);
                color: map-get($colors, livingcoral);
                cursor: pointer;
            }        
        }
    }
}