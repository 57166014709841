.work {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    height: 100%;
    min-height: 100vh;
    background-color: map-get($colors, tropicalice);
    animation-duration: 0.25s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;

    &.-fadeIn {          
        animation-name: fadeIn;
    }
    
    &.-fadeInRight {
        animation-name: fadeInRight;
    }

    h2,
    p,
    li {
        font-family: map-get($fonts, primary);
    }
}

.skills {
    width: 100%;
    text-align: right;
    padding: 100px 0;

    .title {
        font-size: 20px;
        font-weight: bold;
    }
    &__block {
        padding-right: 30px;

        .title {
            font-size: 20px;
            font-weight: bold;
            padding-top: 30px;
        }        

        li {
            list-style: none; 
            font-size: 30px;
            font-weight: bold;
        }
    }
}