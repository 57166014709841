.second {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; 
    background-color: map-get($colors, dark);      

    .title {
        font-family: map-get($fonts, primary);        
        color: map-get($colors, white);        
        padding: 0 0 50px;
        margin: 0;
        font-size: 3.5rem; 
        text-align: center;               

        &.-bold {
            font-weight: bold;            
        }

        @media screen and (min-width: map-get($media, mobile)) and  (max-width: map-get($media, mobileLandscape)) {
            font-size: 2.8rem;
        }
        
        .mobileSpan {
            @media screen and (max-width: map-get($media, mobile)) {
                color: map-get($colors, primaryPink);
                font-style: italic;
                font-size: 1.8rem;
            }

            .fas {
                display: none;

                @media screen and (max-width: map-get($media, mobile)) {
                    display: block;
                    font-size: 1.3rem;
                    padding-top: 20px;
                }
            }
        } 

        @media screen and (max-width: map-get($media, mobile)) {
            font-size: 2.5rem;
        }
    }

}