.section {
    scroll-snap-align: start;
    display: block;
    overflow: auto;
    width: 100%;
    height:100%;
    min-height: 100vh;
    padding-top: 0px;
    padding-bottom: 0px;
}

