.hero {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; 
    min-height: 100vh;

    .title {
        font-family: map-get($fonts, primary);        
        color: map-get($colors, darkgray);        
        padding: 0;
        margin: 0;        
        font-size: calc(3rem + (45 - 20) * ((100vw - 300px) / (1600 - 300)));
        text-align: right;
        padding-right: 10px;

        &.-bold {
            font-weight: bold;
        }

        @media screen and (max-width: map-get($media, mobile)) {
            font-size: 2.2rem;                        
        }
    
        .quoteAuthor {
        display: block;   
        padding-top: 10px;             
        font-size: 1.4rem;
        font-family: map-get($fonts, secondary);
        font-weight: bold;
        font-style: italic;
        color: map-get($colors, lightgray);
        text-align: right;
    }        
    }

 

    .preamble {
        font-family: map-get($fonts, secondary);
        color: map-get($colors, gray);
        font-size: 4rem;
    }

    .point-down {
        display: block;
        cursor: pointer;
        position: absolute;
        bottom: 30px;
        font-size: 20px;   
        animation: jumpingHand 2s infinite;
        
        a {
            color: map-get($colors, dark);
            transition: all 0.3s ease;

            &:hover {
                color: map-get($colors, tropicalice);
            }
        }
    }
}