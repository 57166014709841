.title {
    font-size: 5srem;
    letter-spacing: 1px;
    font-weight: 300;
    font-family: map-get($fonts, primary);
}

.preamble {
    font-size: 5rem;
    padding-top: 0;
    margin-top: 0;    
}

p {
    font-family: map-get($fonts, secondary);
}