.skillTree {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    max-width: 600px;
    width: 100%;
    padding: 100px 0;
    min-height: 90vh;
    font-family: map-get($fonts, primary);

    .leftSide {
        display: flex;
        flex-flow: column nowrap;
        position: absolute;
        left: 0;
        padding-top: 100px;        
    }
    
    .rightSide {
        display: flex;
        flex-flow: column nowrap;        
        position: absolute;
        right: 0;
        padding-top: 184px;        
    }

    &__block {        
        position: relative;
        display: inline-block;        
        left: 0;
        padding: 10px 0 100px;
        text-align: right;
        // border: 1px dotted gray;
        width: 100%;
        width: 200px;
        font-size: 1rem;
        font-weight: bold;  

        a {
            display: inline-block;
            color: inherit;
            text-decoration: none;

            &:after {
                content: '';
                width: 0;
                height: 2px;
                display: block;
                background-color: map-get($colors, black);
                transition: width 0.3s ease;
            }

                &:hover {
                    &:after {
                        content: '';
                        width: 100%;
                    }
                }               
        }

        .year {
            padding: 0;
            margin: 5px 0;
            font-size: 1.5rem;
        }
        
        .dot {
            position: absolute;
            top: 10px;
            right: -115px;
            display: flex;
            z-index: 5; 
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;            
            border-radius: 50px;
            background-color: map-get($colors, dark);
            color: map-get($colors, white);
            font-size: 0.7rem;
            transition: all 0.3s ease;

            // .dotInfo {    
            //     opacity: 0;            
            //     position: absolute;
            //     z-index: 1000;     
            //     width: 200px;
            //     height: 100%;                    
            //     padding: 0;            
            //     background-color: inherit;
            //     color: inherit;
            //     text-align: center;
            //     line-height: 150%;
            //     font-size: 0.8rem;
            //     border-radius: 5px;
            //     transition: all 0.3s ease;

            //     p {
            //         padding: 0;
            //         margin: 0;                    
            //     }
            // }

            &:hover {         
                .dotInfo {
                    opacity: 1;
                    padding: 10px 20px;                      
                    width: 200px;                                       
                }
            }
        }

        &.-right {
            width: 100%;
            width: 200px;
            text-align: left;  

            .dot {
                left: -115px;
            }                 
        }
    }

    &__timeline {        
        position: relative;
        display: block;
        margin-top: 100px; 
        width: 100%;
        height: 100%;
        min-height: 100vh;
        
        .circle,
        .future {        
            display: flex;
            justify-content: center;
            align-items: center;                     
            position: absolute;
            left: 50%;     
            margin-left: -30px;       
            z-index: 2;       
            width: 60px;
            height: 60px;
            border-radius: 50%;            
            background-color: map-get($colors, dark);
            color: map-get($colors, white);
            text-align: center;            
        }

        .future {
            bottom: 0; 
            
            &:hover {
                + .bottom {
                    opacity: 1;
                }
            }
        }
    
        .line {            
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -1px;
            z-index: 1;
            display: inline-block;                        
            width: 2px;
            height: 100%;                           
            background-color: map-get($colors, dark);
        }  
        
        .bottom {
            opacity: 0;
            position: absolute;
            bottom: -50px;
            text-align: center;
            width: 100%;
            transition: opacity 0.3s ease;
        }
    }

}