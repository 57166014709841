// Colors
$colors: (
    black: #000000,
    white: #ffffff,
    dark: #141414,
    primaryPink: #f50057,
    lighterPink: #ff5983,
    gray: #2D2D2D,
    darkgray: #302F2F,    
    lightgray: #DCDCDC,
    watermelon: #FA2147,
    tropicalice: #1ACEC5,
    livingcoral: #FF6F61,
);

// Fonts
@import url('https://fonts.googleapis.com/css?family=Passion+One|Rajdhani');
@import url('https://fonts.googleapis.com/css?family=Archivo|Archivo+Black|Archivo+Narrow');

$fonts: (
  // primary: #{'Passion One', Helvetica, sans-serif},
  // secondary: #{'Rajdhani', Helvetica, sans-serif},  
  primary: #{'Archivo Narrow', Helvetica, sans-serif},
  bold: #{'Archivo Black', Helvetica, sans-serif},  
  secondary: #{'Archivo', Helvetica, sans-serif},  
);

// Media queries
$media: (
  mobile: 375px,
  mobileLandscape: 667px,
  tablet: 720px,
  desktop: 1024px,
);