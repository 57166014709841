.nav {
    position: absolute;
    left: 30px;
    bottom: 30px;
    
    &__list {
        list-style: none;
        float: left;
    }

    &__item {
        padding: 0;
        margin: 0;
        font-size: 4rem;

        a {
            font-family: map-get($fonts, primary);
            color: map-get($colors, lighterPink);
            text-decoration: none;
            background:
            linear-gradient(
            to right, map-get($colors, lighterPink) 0%,
            map-get($colors, lighterPink) 100%
            );
            background-position: 0 100%;
            background-repeat: repeat-y;
            background-size: 4px 4px;
            padding-left: 15px;
            padding-right: 15px;
            transition: background-size 0.5s ease;

            &:hover {
                background-size: 170% 4px;                
                color: #ffffff;
            }
        }
    }
}