.logo {
    position: fixed;
    z-index: 999;
    top: 40px;
    left: 50px;
    font-family: map-get($fonts, primary);        
    color: map-get($colors, darkgray);     
    font-size: 1.5rem;
    text-decoration: none;
    
    @media screen and (min-width: map-get($media, mobile)) and  (max-width: map-get($media, mobileLandscape)) {
        top: 10px;
        left: 20px;    
    }

    &.-white {
        color: map-get($colors, white);
        animation: fadeIn 0.3s;
        
        &:after {
            background-color: map-get($colors, white);
        }

        @media screen and (max-width: map-get($media, mobile)) {
            animation: fadeIn 0.1s;
        }
    }

    &:after {
        content: '';
        width: 0;
        height: 2px;
        display: block;
        background-color: map-get($colors, darkgray);
        transition: width 0.3s ease;
    }

        &:hover {
            &:after {
                content: '';
                width: 100%;
            }
        }    

    a {
        color: inherit;
        text-decoration: none;
        
        &:after {
            content: '';
            width: 0;
            height: 2px;
            display: block;
            background-color: map-get($colors, darkgray);
            transition: width 0.3s ease;
        }

        &:hover {
            &:after {
                content: '';
                width: 100%;
            }
        }
    }
}
