@import url(https://fonts.googleapis.com/css?family=Passion+One|Rajdhani);
@import url(https://fonts.googleapis.com/css?family=Archivo|Archivo+Black|Archivo+Narrow);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 5srem;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: Archivo Narrow, Helvetica, sans-serif; }

.preamble {
  font-size: 5rem;
  padding-top: 0;
  margin-top: 0; }

p {
  font-family: Archivo, Helvetica, sans-serif; }

.hero {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh; }
  .hero .title {
    font-family: Archivo Narrow, Helvetica, sans-serif;
    color: #302F2F;
    padding: 0;
    margin: 0;
    font-size: calc(3rem + (45 - 20) * ((100vw - 300px) / (1600 - 300)));
    text-align: right;
    padding-right: 10px; }
    .hero .title.-bold {
      font-weight: bold; }
    @media screen and (max-width: 375px) {
      .hero .title {
        font-size: 2.2rem; } }
    .hero .title .quoteAuthor {
      display: block;
      padding-top: 10px;
      font-size: 1.4rem;
      font-family: Archivo, Helvetica, sans-serif;
      font-weight: bold;
      font-style: italic;
      color: #DCDCDC;
      text-align: right; }
  .hero .preamble {
    font-family: Archivo, Helvetica, sans-serif;
    color: #2D2D2D;
    font-size: 4rem; }
  .hero .point-down {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    font-size: 20px;
    -webkit-animation: jumpingHand 2s infinite;
            animation: jumpingHand 2s infinite; }
    .hero .point-down a {
      color: #141414;
      transition: all 0.3s ease; }
      .hero .point-down a:hover {
        color: #1ACEC5; }

.nav {
  position: absolute;
  left: 30px;
  bottom: 30px; }
  .nav__list {
    list-style: none;
    float: left; }
  .nav__item {
    padding: 0;
    margin: 0;
    font-size: 4rem; }
    .nav__item a {
      font-family: Archivo Narrow, Helvetica, sans-serif;
      color: #ff5983;
      text-decoration: none;
      background: linear-gradient(to right, #ff5983 0%, #ff5983 100%);
      background-position: 0 100%;
      background-repeat: repeat-y;
      background-size: 4px 4px;
      padding-left: 15px;
      padding-right: 15px;
      transition: background-size 0.5s ease; }
      .nav__item a:hover {
        background-size: 170% 4px;
        color: #ffffff; }

.skillTree {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-width: 600px;
  width: 100%;
  padding: 100px 0;
  min-height: 90vh;
  font-family: Archivo Narrow, Helvetica, sans-serif; }
  .skillTree .leftSide {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    position: absolute;
    left: 0;
    padding-top: 100px; }
  .skillTree .rightSide {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    position: absolute;
    right: 0;
    padding-top: 184px; }
  .skillTree__block {
    position: relative;
    display: inline-block;
    left: 0;
    padding: 10px 0 100px;
    text-align: right;
    width: 100%;
    width: 200px;
    font-size: 1rem;
    font-weight: bold; }
    .skillTree__block a {
      display: inline-block;
      color: inherit;
      text-decoration: none; }
      .skillTree__block a:after {
        content: '';
        width: 0;
        height: 2px;
        display: block;
        background-color: #000000;
        transition: width 0.3s ease; }
      .skillTree__block a:hover:after {
        content: '';
        width: 100%; }
    .skillTree__block .year {
      padding: 0;
      margin: 5px 0;
      font-size: 1.5rem; }
    .skillTree__block .dot {
      position: absolute;
      top: 10px;
      right: -115px;
      display: -webkit-flex;
      display: flex;
      z-index: 5;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: #141414;
      color: #ffffff;
      font-size: 0.7rem;
      transition: all 0.3s ease; }
      .skillTree__block .dot:hover .dotInfo {
        opacity: 1;
        padding: 10px 20px;
        width: 200px; }
    .skillTree__block.-right {
      width: 100%;
      width: 200px;
      text-align: left; }
      .skillTree__block.-right .dot {
        left: -115px; }
  .skillTree__timeline {
    position: relative;
    display: block;
    margin-top: 100px;
    width: 100%;
    height: 100%;
    min-height: 100vh; }
    .skillTree__timeline .circle,
    .skillTree__timeline .future {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      z-index: 2;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #141414;
      color: #ffffff;
      text-align: center; }
    .skillTree__timeline .future {
      bottom: 0; }
      .skillTree__timeline .future:hover + .bottom {
        opacity: 1; }
    .skillTree__timeline .line {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      z-index: 1;
      display: inline-block;
      width: 2px;
      height: 100%;
      background-color: #141414; }
    .skillTree__timeline .bottom {
      opacity: 0;
      position: absolute;
      bottom: -50px;
      text-align: center;
      width: 100%;
      transition: opacity 0.3s ease; }

.section {
  scroll-snap-align: start;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px; }

.socialLinks {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .socialLinks .link {
    font-size: 1.2rem;
    padding: 15px 25px;
    margin: 10px;
    color: #141414;
    font-family: Archivo Narrow, Helvetica, sans-serif;
    text-decoration: none;
    background-color: transparent;
    transition: all 0.3s ease; }
    .socialLinks .link i {
      padding-left: 10px; }
    .socialLinks .link:focus {
      outline: none; }
    .socialLinks .link:hover {
      border: 2px solid #1ACEC5;
      color: #1ACEC5;
      cursor: pointer; }
    .socialLinks .link.-neg {
      border: 2px solid #ffffff;
      color: #ffffff; }
      .socialLinks .link.-neg:hover {
        border: 2px solid #FF6F61;
        color: #FF6F61;
        cursor: pointer; }

.second {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: #141414; }
  .second .title {
    font-family: Archivo Narrow, Helvetica, sans-serif;
    color: #ffffff;
    padding: 0 0 50px;
    margin: 0;
    font-size: 3.5rem;
    text-align: center; }
    .second .title.-bold {
      font-weight: bold; }
    @media screen and (min-width: 375px) and (max-width: 667px) {
      .second .title {
        font-size: 2.8rem; } }
    @media screen and (max-width: 375px) {
      .second .title .mobileSpan {
        color: #f50057;
        font-style: italic;
        font-size: 1.8rem; } }
    .second .title .mobileSpan .fas {
      display: none; }
      @media screen and (max-width: 375px) {
        .second .title .mobileSpan .fas {
          display: block;
          font-size: 1.3rem;
          padding-top: 20px; } }
    @media screen and (max-width: 375px) {
      .second .title {
        font-size: 2.5rem; } }

.work {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 50px;
  height: 100%;
  min-height: 100vh;
  background-color: #1ACEC5;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  transition-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
  .work.-fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn; }
  .work.-fadeInRight {
    -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight; }
  .work h2,
  .work p,
  .work li {
    font-family: Archivo Narrow, Helvetica, sans-serif; }

.skills {
  width: 100%;
  text-align: right;
  padding: 100px 0; }
  .skills .title {
    font-size: 20px;
    font-weight: bold; }
  .skills__block {
    padding-right: 30px; }
    .skills__block .title {
      font-size: 20px;
      font-weight: bold;
      padding-top: 30px; }
    .skills__block li {
      list-style: none;
      font-size: 30px;
      font-weight: bold; }

.button {
  font-size: 1.2rem;
  padding: 20px 40px;
  border: 2px solid #141414;
  color: #141414;
  font-family: Archivo Narrow, Helvetica, sans-serif;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.3s ease; }
  .button i {
    padding-left: 10px; }
  .button:focus {
    outline: none; }
  .button:hover {
    border: 2px solid #FF6F61;
    color: #FF6F61;
    cursor: pointer; }
  .button.-neg {
    border: 2px solid #ffffff;
    color: #ffffff; }
    .button.-neg:hover {
      border: 2px solid #FF6F61;
      color: #FF6F61;
      cursor: pointer; }

.logo {
  position: fixed;
  z-index: 999;
  top: 40px;
  left: 50px;
  font-family: Archivo Narrow, Helvetica, sans-serif;
  color: #302F2F;
  font-size: 1.5rem;
  text-decoration: none; }
  @media screen and (min-width: 375px) and (max-width: 667px) {
    .logo {
      top: 10px;
      left: 20px; } }
  .logo.-white {
    color: #ffffff;
    -webkit-animation: fadeIn 0.3s;
            animation: fadeIn 0.3s; }
    .logo.-white:after {
      background-color: #ffffff; }
    @media screen and (max-width: 375px) {
      .logo.-white {
        -webkit-animation: fadeIn 0.1s;
                animation: fadeIn 0.1s; } }
  .logo:after {
    content: '';
    width: 0;
    height: 2px;
    display: block;
    background-color: #302F2F;
    transition: width 0.3s ease; }
  .logo:hover:after {
    content: '';
    width: 100%; }
  .logo a {
    color: inherit;
    text-decoration: none; }
    .logo a:after {
      content: '';
      width: 0;
      height: 2px;
      display: block;
      background-color: #302F2F;
      transition: width 0.3s ease; }
    .logo a:hover:after {
      content: '';
      width: 100%; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes jumpingHand {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@keyframes jumpingHand {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; }

* {
  box-sizing: border-box; }

.snapWrap {
  height: 100vh;
  -webkit-scroll-snap-type: mandatory;
      -ms-scroll-snap-type: mandatory;
          scroll-snap-type: mandatory;
  -webkit-scroll-snap-points-y: repeat(100vh);
      -ms-scroll-snap-points-y: repeat(100vh);
          scroll-snap-points-y: repeat(100vh);
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory; }

