// Simple Fade in
@keyframes fadeIn {
  0% {
      opacity: 0;
    }
  100% {
      opacity: 1;
    }
}

// Fade in Right
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

// Jumping hand
@keyframes jumpingHand {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}