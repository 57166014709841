@import './config';
@import './typography';

//Components
@import './hero';
@import './nav';
@import './skilltree';
@import './section';
@import './social';

// Pages
@import './second';
@import './work';

// Elements
@import './button';
@import './logo';

// Styling
@import './animations';


html,
body {    
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;      
}

* {
    box-sizing: border-box;
}

.snapWrap {
//  overflow: scroll;
  height: 100vh;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
 